/* Custom Scrollbar Styles */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 10px;
  transition: all 0.3s ease;
}

::-webkit-scrollbar-thumb:hover {
  background: #777;
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #999 #f1f1f1;
}

/* For elements that need custom scrollbar */
.scrollable-content {
  overflow-y: auto;
  scrollbar-gutter: stable;
} 